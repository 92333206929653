const whatwedo = [
	{
		id: 1,
		service_name: 'web-development',
		service_detail_title: 'WEB DEVELOPMENT',
		service_subtitle: 'ENGAGING , PURPOSEFUL , AND CREATIVE',
		service_img: 'si1.png',
		service_right: `We craft websites and applications that are guided by research and engineered to
		meet business goals. Our team not only keeps pace with rapid industry advances, but
		also focus on security, development best practices, and code reviews ensure long
		term usability and sustainability of every project we release. We create intuitive,
		simple, and engaging experiences for all users, on any screen size and from any
		device.`,
		web_services: [
			[
				'Responsive Website Design',
				'Web application',
				'CRM Application',
				'Content Management System'
			],
			[
				'E-Commerce',
				'Mobile Backend / API Development',
				'Full Stack Development Team',
				'UI/UX Design / WireFraming'
			]
		]
	},
	{
		id: 2,
		service_name: 'mobile-apps',
		service_detail_title: 'MOBILE APPS',
		service_subtitle: 'EXPERT DEVELOPMENT. ENGAGED USERS.',
		service_img: 'si2.png',
		service_right: `From strategy to deployment, our team of experts craft custom applications that are
		designed to make an impact on your target audience. We develop an app that focuses
		on friendly user interface with rich design, clear direction, and smooth flow.`,
		web_services: [
			['	Mobile Strategy', 'iOS App Development,', 'Android App Development'],
			['Cross-platform App Development using Ionic', 'Distribution and App Management']
		]
	},
	{
		id: 3,
		service_name: 'digital-marketing',
		service_detail_title: 'DIGITAL MARKETING',
		service_subtitle: 'TAKING BUSINESS RESULTS TO THE NEW HEIGHTS.',
		service_img: 'si3.png',
		service_right: `We help businesses stand out and draw consumers by developing customized strategies
		and measurable objectives. To accelerate growth of your business, we use an
		integrated approach to digital marketing that combines branding, content marketing,
		search engine optimization, online advertising, social media marketing and website
		optimization.`,
		web_services: [
			['Analytics Review', 'Search engine optimization', 'Branding', 'Social media marketing'],
			['Website optimization', 'Email marketing', 'Pay-per-click ad campaign management']
		]
	}
];

export default whatwedo;
