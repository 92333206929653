const homeTxt = [
	{
		img: '11',
		header: 'DIGITAL',
		subText: 'WE LOVE DIGITAL AND IT IS WHAT WE DO BEST'
	},
	{
		img: '22',
		header: 'STRATEGY',
		subText: 'FORM CERTAINTY IN DISORDER'
	},
	{
		img: '33',
		header: 'SOLUTIONS',
		subText: 'SERVE TO INSPIRE, INNOVATE, ACCOMPLISH'
	}
];

export default homeTxt;
