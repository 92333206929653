const whoweare = [
	{
		id: 1,
		img: 'about1.png',
		feature_title: 'We deliver smarter solutions',
		feature_subtitle: `We identify the most critical challenges, perform extensive research and build
		an optimal strategy. Some see challenges. We see endless possibilities. Keeping
		users as our focus, the result is always a smarter, more effective solution that
		exceeds expectations.`
	},
	{
		id: 2,
		img: 'about2.png',
		feature_title: 'One goal, extraordinary team',
		feature_subtitle: `We are industry-leading specialists hand-picked from across Yangon, New York,
		and Seoul. Our team of talents with diverse cultures gathered in the heart of
		Myanmar with one goal that makes us one - to deliver products and solutions that
		will stretch far beyond ordinary. Together, we make an extraordinary team that
		make extraordinary outcome.`
	},
	{
		id: 3,
		img: 'about3.png',
		feature_title: 'People is our treasure, joy is our drive',
		feature_subtitle: `We create not only solutions but connections. We meet and build lifelong
		friendships, learn so much and grow as a whole. Our definition of success and
		source of joy is making difference in lives of many, treasuring every
		relationship and partnership. We believe happy people bring joyful digital
		experience.`
	}
];

export default whoweare;
