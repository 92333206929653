import React from 'react';
import '../css/loading.css';

function Loading() {
	return (
		<div className="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}

export default Loading;
