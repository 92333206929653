import bridgerock from '../img/featured_works/bridgerock_d.jpg';
import green from '../img/featured_works/green_d.jpg';
import rapid from '../img/featured_works/rapid_d.jpg';
import rindle from '../img/featured_works/rindle_d.jpg';

import bridgerock_m from '../img/featured_works/bridgerock_m.jpg';
import green_m from '../img/featured_works/green_m.jpg';
import rapid_m from '../img/featured_works/rapid_m.jpg';
import rindle_m from '../img/featured_works/rindle_m.jpg';

// JSON DATA
const projects = [
	{
		id: 1,
		projectName: 'Rapid Receipt',
		projectDescription: `
		<a href="https://rapidreceipt.io/" target="_blank" rel="noreferrer">
		Rapid Receipt
		</a>
		 is an easy-to-use expense management solution that tracks expenses of a business or an individual. Using OCR (Optical Character Recognition) technology, it automatically extracts and converts information from photos of receipts into digital data, organizes the data and draws analysis. Key features include sorting, filtering, and organizing expenses by date, expense value, category, vendor, and project.`,
		client: 'Bridge Rock, USA',
		img: rapid,
		mobile_img: rapid_m,
		platform: ['iOS App', 'Android', 'React Native']
	},
	{
		id: 2,
		projectName: 'Rindle',
		projectDescription: `
		<a href="https://rindle.com/" target="_blank" rel="noreferrer">
		Rindle
		</a>
		is a task and project management automation application for business that easily tracks projects and tasks within the platform.',
			client: 'Rindle, USA`,
		img: rindle,
		mobile_img: rindle_m,
		platform: ['iOS App', 'React Native']
	},
	{
		id: 3,
		projectName: 'Greensheet',
		projectDescription: `Greensheet is a venture capital fund management tool for an investment company
			<a href="https://www.lererhippeau.com/" target="_blank" rel="noreferrer">
				Lerer Hippeau
			</a>
		. It empowers them to centrally manage different cycles of investment of their partners. Key features include aggregated data of companies investment history, growth, and revenue forecast.`,
		img: green,
		mobile_img: green_m,
		platform: ['Web App', 'Vue.js', 'Laravel']
	},
	{
		id: 4,
		projectName: 'BRAMS',
		projectDescription: ` Bridgerock Asset Management System (BRAMS) is a powerful management asset tool created for a property management company, 
			<a href="http://dev.bridgerockcap.com:8080/" target="_blank" rel="noreferrer">
				Bridge Rock Capital
			</a>
		. Key features include analysis of performance of individual property, task management of managers, collation and graphical display of gathered data.`,
		client: 'Bridge Rock Capital, USA',
		img: bridgerock,
		mobile_img: bridgerock_m,
		platform: ['Web App', 'Laravel', 'React']
	}
];

export default projects;
