import React from 'react';
import '../css/footer.css';

function Footer() {
	return (
		<section className="footer-Content" id="contact footer">
			<div className="container">
				<div className="row">
					<div className="col-md-2 col-sm-12 footerLogo">
						<h3>
							<img src={require('../img/logo_white.png')} height="30" />
						</h3>
						<div className="textwidget">
							<p>DSS CORP</p>
						</div>
					</div>
					<div className="col-md-8 col-sm-12">
						<div className="widget">
							<h3 className="block-title contact-title">Contact Us</h3>
							<ul className="contact-footer">
								<li>
									<span>
										<a href="mailto:info@dss.com.mm">info@dss.com.mm</a>
									</span>
								</li>
								<li>
									<span>+95 09 890 279087</span>
								</li>
								<li>
									<span>
										531 (C)1, 2nd Building - 1A, Marlar Myaing Yeik Thar Lane
										<br></br>Kamaryut Township, Yangon, Myanmar
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Footer;
