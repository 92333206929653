import React from 'react';
import '../css/whoWeAre.css';
import whoweare from '../local_data/whoweare';

export default function WhoWeAre() {
	return (
		<div id="whoweare" className="whoWeAre">
			<section className="section">
				<div className="container whoweare-content">
					<div className="section-header">
						<h2 className="section-title">WHO WE ARE</h2>
						<p className="section-subtitle">
							We are an IT solutions and digital consulting agency focusing on web and mobile app
							development.<br></br> We help companies provide custom digital solutions that drive
							strategic business outcomes.
						</p>
					</div>

					<div className="row">
						{whoweare.map((we) => {
							return (
								<div key={we.id} className="col-lg-4 col-xs-12">
									<div className="featured-box">
										<div className="featured-icon">
											<img
												className="lni-layout"
												loading="eager"
												src={require(`../img/about/${we.img}`)}
											></img>
										</div>
										<div className="featured-content">
											<h4>{we.feature_title}</h4>
											<p>{we.feature_subtitle}</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>
		</div>
	);
}
