import React from 'react';
import '../css/preloader.css';

// preloader
const Preloader = () => {
	return (
		<div id="loader" className="loader">
			<div className="spinner">
				<div className="double-bounce1"></div>
				<div className="double-bounce2"></div>
			</div>
		</div>
	);
};

export default Preloader;
