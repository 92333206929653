import Employee from './Employee';
import { employeeGet } from '../api/employee';
import { React, useState, useEffect } from 'react';
import FetchError from './FetchError';

function EmployeeDisplay() {
	const [employeeList, setEmployeeList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [apiError, setApiError] = useState(false);

	const getEmployee = async () => {
		try {
			setIsLoading(true);
			const data = await employeeGet();
			setEmployeeList(data);
		} catch (error) {
			setApiError(true);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getEmployee();
	}, []);

	return (
		<div>
			<section id="team" className="section">
				<div className="container">
					<div className="section-header">
						<h2 className="section-title">MEET OUR TEAM</h2>
					</div>
					{employeeList || apiError ? (
						<Employee isLoading={isLoading} employeeList={employeeList} />
					) : (
						<FetchError />
					)}
				</div>
			</section>
		</div>
	);
}

export default EmployeeDisplay;
