import React from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { ReactComponent as UpArrow } from '../img/upArrow.svg';
import '../css/App.css';

const ScrollTop = () => {
	return <ScrollToTop className="scrolltotop" smooth component={<UpArrow className="upArrow" />} />;
};

export default ScrollTop;
