import React from 'react';
import err_msg from '../img/err_mesg.png';
import '../css/err_msg.css';

function FetchError() {
	return (
		<img className="err_msg" src={err_msg} alt="This Section is not available yet. Stay Tuned!" />
	);
}

export default FetchError;
