import './css/App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import WhoWeAre from './components/WhoWeAre';
import WhatWeDo from './components/WhatWeDo';
import FeaturedWorks from './components/FeaturedWorks';
import VideoParallax from './components/VideoParallax';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import EmployeeDisplay from './components/EmployeeDisplay';
import GoogleMap from './components/GoogleMap';
import { useEffect, useState } from 'react';
import { images } from './local_data/images';
import Preloader from './components/Preloader';

export default function App() {
	const [imgsLoaded, setImgsLoaded] = useState(false);

	useEffect(() => {
		const loadImage = (image) => {
			return new Promise((resolve, reject) => {
				const loadImg = new Image();
				loadImg.src = image;
				loadImg.onload = () => resolve(image);

				loadImg.onerror = (err) => reject(err);
			});
		};

		Promise.all(images.map((image) => loadImage(image)))
			.then(() => setImgsLoaded(true))
			.catch((err) => console.log('Failed to load images', err));
	}, []);

	return (
		<>
			{imgsLoaded ? (
				<>
					<header>
						<Navbar />
					</header>
					<section>
						<Home />
						<WhoWeAre />
						<WhatWeDo />
						<FeaturedWorks />
						<VideoParallax />
						<EmployeeDisplay />
						<Contact />
						<GoogleMap />
					</section>
					<footer>
						<Footer />
					</footer>
					<ScrollTop />
				</>
			) : (
				<Preloader />
			)}
		</>
	);
}
