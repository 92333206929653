import '../css/team.css';
import Loading from './Loading';
import dummyProfile from '../img/dummy_profile.png';
import { useState } from 'react';

const Employee = ({ isLoading, employeeList }) => {
	const [imgLoaded, setImgLoaded] = useState(false);

	return (
		<div>
			{isLoading === false ? (
				<div className="row justify-content-center">
					{employeeList?.map((employee) => {
						return (
							<div className="col-md-4 col-6" key={employee.id}>
								<div className="single-team">
									<img
										onLoad={() => setImgLoaded(true)}
										className="profile-photo"
										src={imgLoaded ? employee.avatar : dummyProfile}
									/>
									<div className="team-details">
										<div className="team-inner">
											<h4 className="team-title">{employee.enName}</h4>
											<p>{employee.position}</p>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default Employee;
