import { React, useEffect, useState } from 'react';
import '../css/featuredWorks.css';
import parse from 'html-react-parser';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import projects from '../local_data/projects.js';

function FeaturedWorks() {
	// SCREEN SIZE MONITOR START
	const [screen, setscreen] = useState(window.innerWidth);

	const setDimension = () => {
		setscreen(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', setDimension);
		return () => {
			window.removeEventListener('resize', setDimension);
		};
	}, [screen]);
	// SCREEN SIZE MONITOR END

	return (
		<div className="featuredWorks" id="works">
			<div className="section-header">
				<h2 className="section-title">Featured works</h2>
			</div>

			{/* --------Carousel--------- */}
			<Swiper
				// install Swiper modules
				modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
				spaceBetween={50}
				slidesPerView={1}
				loop
				navigation
				pagination={{ clickable: true }}
				autoplay={{ delay: 4000 }}
			>
				{projects.map((project) => {
					return (
						<SwiperSlide key={project.id}>
							<div className="swiperContent">
								<div className="text-left">
									<div className="project-title">
										<p className="lbl-project">PROJECT</p>
										<p className="project-name">{project.projectName}</p>
									</div>
									{/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
									<br />
									<p className="project-description">{parse(project.projectDescription)}</p>
									<br />
									<div className="client-area">
										<p className="lbl-client">CLIENT</p>
										<p className="client-title">{project.client}</p>
									</div>
									<br />
									<div className="platforms">
										{project.platform.map((platformName, index) => {
											// eslint-disable-next-line react/jsx-key
											return (
												<p key={index} className="platform">
													{platformName}
												</p>
											);
										})}
									</div>
								</div>
								<div className={`img-right ${project.projectName.toLowerCase()}`}>
									{screen < 1000 ? (
										<img src={project.mobile_img} alt={project.client} />
									) : (
										<img src={project.img} alt={project.client} />
									)}
								</div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
}

export default FeaturedWorks;
