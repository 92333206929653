import React from 'react';
import { Parallax, Background } from 'react-parallax';
import '../css/videoParallax.css';
import videoURL from '../video/dss.mp4';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const VideoParallax = () => {
	return (
		<Parallax strength={500}>
			<Background className="custom-bg">
				<video width="2164px" className="parallaxVideo" muted autoPlay loop src={videoURL}></video>
			</Background>
			<div className="content">
				<AnimationOnScroll
					animateIn="animate__animated animate__zoomInUp"
					animateOnce={true}
					duration={1}
				>
					<h2>IN THE HEART OF YANGON</h2>
				</AnimationOnScroll>
			</div>
		</Parallax>
	);
};

export default VideoParallax;
