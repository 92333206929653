import React from 'react';
import '../css/whatWeDo.css';
import whatwedo from '../local_data/whatwedo';

function WhatWeDo() {
	return (
		<div className="whatWeDo" id="whatwedo">
			<section className="section-title-ctr">
				<div className="container-fluid">
					<div className="section-header">
						<h2 className="section-title">WHAT WE DO</h2>
					</div>
				</div>
			</section>

			{whatwedo.map((eachWhatWeDo) => {
				return (
					<section
						key={eachWhatWeDo.id}
						className="section service-detail"
						id={eachWhatWeDo.service_name}
					>
						<div className="container-fluid">
							<div className="row justify-content-between  ctn-row-right">
								<div className="col-lg-5 col-md-12 service-detail-title">
									<h2>{eachWhatWeDo.service_detail_title}</h2>
									<p className="service-subtitle">{eachWhatWeDo.service_subtitle}</p>
									<div>
										<img
											loading="eager"
											className="service-icon"
											src={require(`../img/services/${eachWhatWeDo.service_img}`)}
										/>
									</div>
								</div>
								<div className="col-lg-6 col-md-12 service-right">
									<p>{eachWhatWeDo.service_right}</p>
									<h3>SERVICES INCLUDE</h3>
									<div className="row">
										<div className="col-6">
											<ul>
												{eachWhatWeDo.web_services[0].map((web_service, index) => {
													return <li key={index}>{web_service}</li>;
												})}
											</ul>
										</div>
										<div className="col-6">
											<ul>
												{eachWhatWeDo.web_services[1].map((web_service, index) => {
													return <li key={index}>{web_service}</li>;
												})}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				);
			})}
		</div>
	);
}

export default WhatWeDo;
