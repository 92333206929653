import Api from './api';

// eslint-disable-next-line no-unused-vars
export const employeeGet = async () => {
	return Api.get('/employees')
		.then((response) => {
			return response.data.data;
		})
		.catch((err) => {
			console.log(err);
		});
};
