import bg1 from '../img/bg/11.jpg';
import bg2 from '../img/bg/22.jpg';
import bg3 from '../img/bg/33.jpg';
import about1 from '../img/about/about1.png';
import about2 from '../img/about/about2.png';
import about3 from '../img/about/about3.png';

import bridgerock from '../img/featured_works/bridgerock_d.jpg';
import green from '../img/featured_works/green_d.jpg';
import rapid from '../img/featured_works/rapid_d.jpg';
import rindle from '../img/featured_works/rindle_d.jpg';

export const images = [bg1, bg2, bg3, about1, about2, about3, bridgerock, green, rapid, rindle];
