import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import mapIcon from '../img/map_icon.png';
import mapStyle from '../local_data/mapStyle';

const containerStyle = {
	overflow: 'hidden',
	width: '100%',
	height: '500px'
};

const center = {
	lat: 16.82519060434947,
	lng: 96.12821656482238
};

const markerPosition = {
	lat: 16.82519060434947,
	lng: 96.12821656482238
};

function MyComponent() {
	return (
		// eslint-disable-next-line no-undef
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API}>
			<GoogleMap
				mapContainerStyle={containerStyle}
				options={{ styles: mapStyle }}
				tilt={180}
				center={center}
				zoom={16}
			>
				{/* Child components, such as markers, info windows, etc. */}
				<>
					<Marker position={markerPosition} icon={mapIcon} />
				</>
			</GoogleMap>
		</LoadScript>
	);
}

export default React.memo(MyComponent);
