/* eslint-disable no-undef */
import React from 'react';
import { Parallax } from 'react-parallax';
import '../css/home.css';
import homeTxt from '../local_data/homeText';

function Home() {
	return (
		<section className="home" id="home">
			{homeTxt.map((herotext, index) => {
				return (
					<div key={index}>
						<Parallax
							contentClassName={`parallaxOverlayContent ${herotext.header.toLowerCase()}`}
							strength={700}
							bgImage={require(`../img/bg/${herotext.img}.jpg`)}
							bgImageAlt="bgimage"
							bgImageStyle={{ maxHeight: '130vh' }}
							bgClassName="parallaxBanner"
						>
							<div className="parallaxBannerText" id={`${herotext.header.toLowerCase()}`}>
								<h1 className="callout-header">{herotext.header}</h1>
								<p className="callout-subtext">{herotext.subText}</p>
							</div>
						</Parallax>
					</div>
				);
			})}
		</section>
	);
}

export default Home;
