const mapStyle = [
	{
		featureType: 'all',
		elementType: 'all',
		stylers: [
			{
				saturation: -100
			},
			{
				gamma: 0.5
			}
		]
	}
];

export default mapStyle;
